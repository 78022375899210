import {withAppContext} from "../../../App";
import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class PatientDetailCyclerApplet extends Applet {
    constructor(props) {
        super(props);

        const actStudy = this.view.props.appContext.sessionContext.actStudy;

        let enterpriseComponent = this.getEnterpriseComponent();
        if (actStudy) {
            enterpriseComponent.getAttributes()["CyclerID"].setPicklist("CyclerActStudy");
        } else {
            enterpriseComponent.getAttributes()["CyclerID"].setPicklist("Cycler");
        }

        if ([null, undefined].includes(enterpriseComponent.getAttributeValue("DeviceUSID"))) {
            enterpriseComponent.getAttributes()["CyclerID"].setRequired(false);
        } else {
            enterpriseComponent.getAttributes()["CyclerID"].setRequired(true);
        }

        this.state = {
            Name: "PatientDetailCyclerApplet",
            Modes: {
                View: {
                    Groups: [
                        {
                            "identifier": "group1",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "CyclerID",
                                },
                            ],
                        },
                    ],
                },
                Edit: {
                    Groups: [
                        {
                            "identifier": "group1",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "CyclerID",
                                },
                            ],
                        },
                    ],
                },
            },
            Layout: {
                totalWidth: "36em",
            },
            Controls: {
                CyclerID: {
                    id: "CyclerID",
                    enterpriseComponentAttribute: "CyclerID",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "New": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                    },
                    type: "Select",
                    requiresConfirmation: false,
                    errors: [
                        ...(enterpriseComponent.getAttributes()["CyclerID"].getRequired() ? [{
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        }] : []),
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Cycler",
                        margin: "dense",
                    },
                    ip: {},
                },
            },
        };
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="cycler"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(PatientDetailCyclerApplet);
