import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentAttributePickMapMetadata from "../EnterpriseComponentAttributePickMapMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";
import EnterpriseComponentVisibilityModeMetadata from "../EnterpriseComponentVisibilityModeMetadata";

class PatientAREnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientAR";
        this.defaultCacheSize = 50;

        this.visibilityModes["All"] = new EnterpriseComponentVisibilityModeMetadata(
            "All",  // name
            "ClinicUSID",  // visibilityAttribute
        );
        this.visibilityModes["ProviderView"] = new EnterpriseComponentVisibilityModeMetadata(
            "Provider",  // name
            "ProviderUSID",  // visibilityAttribute
        );
        this.visibilityModes["ClinicView"] = new EnterpriseComponentVisibilityModeMetadata(
            "Clinic",  // name
            "ClinicUSID",  // visibilityAttribute
        );

        this.attributes["BillingAccountUSID"] = new EnterpriseComponentAttributeMetadata(
            "BillingAccountUSID", // name
            false,  // NoCopy
            false,  // NoUpdate
            false,  // Required
            false,  // RequiredForQuery
            "BillingAccount", // Picklist
            null,   // Scale
            null,   // Precision
            36,     // length
            null,   // minLength
            null,   // pattern
            "UUID", // Type
            null,   // default value
            false,  // computed
        );
        this.attributes["BillingAccountCustomer"] = new EnterpriseComponentAttributeMetadata(
            "BillingAccountCustomer", // name
            false, // NoCopy
            true, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["BillingAccountCustomerNo"] = new EnterpriseComponentAttributeMetadata(
            "BillingAccountCustomerNo", // name
            false, // NoCopy
            true, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["BillingAccountSubCustomer"] = new EnterpriseComponentAttributeMetadata(
            "BillingAccountSubCustomer", // name
            false, // NoCopy
            true, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["BillingAccountSubCustomerNo"] = new EnterpriseComponentAttributeMetadata(
            "BillingAccountSubCustomerNo", // name
            false, // NoCopy
            true, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["ClinicUSID"] = new EnterpriseComponentAttributeMetadata(
            "ClinicUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicIdentifier"] = new EnterpriseComponentAttributeMetadata(
            "ClinicIdentifier", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CyclerID"] = new EnterpriseComponentAttributeMetadata(
            "CyclerID", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Cycler",  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CyclerName"] = new EnterpriseComponentAttributeMetadata(
            "CyclerName", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PatientIdentifier"] = new EnterpriseComponentAttributeMetadata(
            "PatientIdentifier", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "PatientIdentifier",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CloudCathID"] = new EnterpriseComponentAttributeMetadata(
            "CloudCathID", // name
            false, // NoCopy
            true, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["FirstName"] = new EnterpriseComponentAttributeMetadata(
            "FirstName", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MiddleInitial"] = new EnterpriseComponentAttributeMetadata(
            "MiddleInitial", // name
            false, // NoCopy
            false, // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["LastName"] = new EnterpriseComponentAttributeMetadata(
            "LastName", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DateOfBirth"] = new EnterpriseComponentAttributeMetadata(
            "DateOfBirth", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$",  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PatientEscalationStatus"] = new EnterpriseComponentAttributeMetadata(
            "PatientEscalationStatus", // name
            false, // NoCopy
            true, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            40,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["LastTurbidityValue"] = new EnterpriseComponentAttributeMetadata(
            "LastTurbidityValue", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            1,     // Scale
            2,     // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["LastTurbidityValueDatetime"] = new EnterpriseComponentAttributeMetadata(
            "LastTurbidityValueDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceUSID"] = new EnterpriseComponentAttributeMetadata(
            "DeviceUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceSerialNumber"] = new EnterpriseComponentAttributeMetadata(
            "DeviceSerialNumber", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceAssignmentStatus"] = new EnterpriseComponentAttributeMetadata(
            "DeviceAssignmentStatus", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceAssignmentDatetime"] = new EnterpriseComponentAttributeMetadata(
            "DeviceAssignmentDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PatientState"] = new EnterpriseComponentAttributeMetadata(
            "PatientState", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnableTurbidityNotifications"] = new EnterpriseComponentAttributeMetadata(
            "EnableTurbidityNotifications", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "Boolean", // Type
            true,   // default value
            false,   // computed
        );
        this.attributes["TurbidityNotificationLevelA"] = new EnterpriseComponentAttributeMetadata(
            "TurbidityNotificationLevelA", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["NotifyAfterInstancesA"] = new EnterpriseComponentAttributeMetadata(
            "NotifyAfterInstancesA", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            0,  // Scale
            1,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TurbidityNotificationLevelB"] = new EnterpriseComponentAttributeMetadata(
            "TurbidityNotificationLevelB", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["NotifyAfterInstancesB"] = new EnterpriseComponentAttributeMetadata(
            "NotifyAfterInstancesB", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            0,  // Scale
            1,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        let sendReminderAfterInstances = new EnterpriseComponentAttributeMetadata(
            "SendReminderAfterInstances", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Clinic",  // Picklist
            1,  // Scale
            2,  // Precision
            null,   // length
            null,   // minLength
            "^([1-9]|[1-9][0-9])(\.([0]|[5]))?$",  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        sendReminderAfterInstances.fPickMaps.push(new EnterpriseComponentAttributePickMapMetadata("Pick1", false, "SendReminderAfterInstances", false, "SendReminderAfterInstances", "Inbound"));
        sendReminderAfterInstances.fPickMaps.push(new EnterpriseComponentAttributePickMapMetadata("Pick2", false, "NotifyAfterInstancesA", false, "NotifyAfterInstances", "Inbound"));
        sendReminderAfterInstances.fPickMaps.push(new EnterpriseComponentAttributePickMapMetadata("Pick3", false, "TurbidityNotificationLevelA", false, "TurbidityNotificationLevel", "Inbound"));
        this.attributes["SendReminderAfterInstances"] = sendReminderAfterInstances;

        this.attributes["PrimaryDoctorUSID"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryDoctorUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "PrimaryPhysician",  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PrimaryDoctorFirstName"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryDoctorFirstName", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PrimaryDoctorLastName"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryDoctorLastName", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PrimaryDoctorFullName"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryDoctorFullName", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,   // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["PrimaryNurseUSID"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryNurseUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "PrimaryNurse",  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PrimaryNurseFirstName"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryNurseFirstName", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PrimaryNurseLastName"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryNurseLastName", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,   // length
            null,   // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PrimaryNurseFullName"] = new EnterpriseComponentAttributeMetadata(
            "PrimaryNurseFullName", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,   // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default PatientAREnterpriseComponentMetadata;
